import { useState,useEffect } from "react"
import {utils,writeFileXLSX} from "xlsx-js-style"
import {Card,Typography,Tooltip,IconButton,TextField,InputAdornment} from '@mui/material'
import {
    ChevronLeft,
    ChevronRight,
    Clear,
    Search,
    GetApp,
  } from "@mui/icons-material";
  import { grey } from "@mui/material/colors";

import { ALL_MONTHLY_REPORT } from "../utils/urls";
import axios from "axios";
import {AllMonthlyReportTable} from './virtual_table'

import dateformat from "dateformat";



  let headers = [
    "ID",
    "REGISTRATION_NO",
    "CHASIS_NO",
    "MODEL",
    "CODE",
    "DAY_COUNT",
    "START_TIME",
    "END_TIME",
    "LOCATION_COUNT",
    "DISTANCE (KM)",
    "RUNNING_TIME",
    "JAM_TIME",
    "IDLE_TIME",
]


const EmptyFeedBack = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant="h4">No Data Found</Typography>
      </div>
    );
  };


const AllMonthlyReport=()=>{

    const [state, setState] = useState({
        date: new Date(),
        data: [],
        original: [],
        search: "",
      });


      useEffect(() => {
        let data = {
          year: state.date.getFullYear(),
          month: state.date.getMonth(),
        };
    
        axios
          .post(ALL_MONTHLY_REPORT, data)
          .then((response) =>
            setState((old) => ({ ...old, original: response.data }))
          )
          .catch((err) => console.log(err));
      }, [state.date]);
    
      useEffect(() => {
        setState((old) => ({ ...old, data: [...old.original] }));
      }, [state.original]);
    
      useEffect(() => {
        if (state.original) {
          let filterData = [...state.original].filter((x) => {
            return (
              x.id.toLowerCase().includes(state.search.toLowerCase()) ||
              x.code.toLowerCase().includes(state.search.toLowerCase()) ||
              x.device_sim_number.toLowerCase().includes(state.search.toLowerCase())
            );
          });
          setState((old) => ({ ...old, data: filterData }));
        }
      }, [state.search]);


      const prevClick = () => {
        const bbb = new Date(state.date);
        bbb.setMonth(bbb.getMonth() - 1);
        setState((old) => ({ ...old, date: bbb }));
      };
    
      const nextClick = () => {
        const bbb = new Date(state.date);
        bbb.setMonth(bbb.getMonth() + 1);
        setState((old) => ({ ...old, date: bbb }));
      };
    
      const handleChange = (e) => {
        let val = e.target.value;
        setState((old) => ({ ...old, search: val }));
      };



      const getTotalDistance = () => {
        let d = state.original.reduce((acc, obj) => acc + obj.distance, 0);
    
        return d.toFixed(2);
      };
    
      const getColumnWidth=()=>{
    
        let data = [...state.data]
        let arr=[
            {wch:data.reduce((w, r) => Math.max(w, r.id.length), headers[0].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.registration_number.trim().length), headers[1].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.chasis_number.trim().length), headers[2].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.model.trim().length), headers[3].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.code.trim().length), headers[4].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.day_count.length), headers[5].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.start_time.trim().length), headers[6].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.end_time.trim().length), headers[7].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.location_count.length), headers[8].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.distance.length), headers[9].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.running_time.trim().length), headers[10].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.congestion_time.trim().length), headers[11].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.idle_time.trim().length), headers[12].length)},
        ]
    
        return arr
      }
    
      const downloadFile = () => {
        const ws = utils.json_to_sheet([...state.data].map(({device_sim_number,...item})=>item));
        ws["!cols"] = getColumnWidth();
        utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, dateformat(state.date, "mmmm-yyyy"));
        writeFileXLSX(wb, dateformat(state.date, "mmmm-yyyy")+".xlsx");
      };
    

      const getTotalDayCount = () => {
        let d = state.original.reduce((acc, obj) => acc + obj.day_count, 0);
        return d.toFixed(0);
      };




    return (
    <div style={{ display: "flex", width: "100%", margin: 10 }}>
      <Card style={{ width: "100%", height: "100%" }}>
      <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
             <div
              style={{
                display: "flex",
                padding: 10,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  padding: 10,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="button">Total Distance</Typography>
                  <Typography variant="body1">
                    {getTotalDistance()} KM
                  </Typography>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="button">Total Day Count</Typography>
                  <Typography variant="body1">
                    {getTotalDayCount()} Days
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 2,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Previous Month">
                  <IconButton onClick={prevClick}>
                    <ChevronLeft color="secondary" fontSize="large" />
                  </IconButton>
                </Tooltip>

                <Typography style={{ marginLeft: 20, marginRight: 20 }}>
                  Report on {dateformat(state.date, "mmmm-yyyy")}
                </Typography>
                <Tooltip title="Next Month">
                  <IconButton onClick={nextClick}>
                    <ChevronRight color="secondary" fontSize="large" />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  padding: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  placeholder="Search by ID and Code"
                  onChange={handleChange}
                  name="search"
                  value={state.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search
                          style={{
                            color: grey[700],
                            padding: 4,
                            cursor: "pointer",
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setState((old) => ({ ...old, search: "" }))
                          }
                        >
                          <Clear
                            style={{
                              color: grey[700],
                              padding: 4,
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Tooltip title="Download">
                  <IconButton onClick={downloadFile}>
                    <GetApp fontSize="large" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
              <div style={{ width: "98%" }}>
                {state.data &&
                  (state.data.length === 0 ? (
                    <EmptyFeedBack />
                  ) : (
                    <AllMonthlyReportTable data={state.data} />
                  ))}
              </div>
            </div>


        </div>
      </Card>
      </div>
    )
}

export default AllMonthlyReport