const BASE_URL = "https://mongo4.sultantracker.com/api/";
// const BASE_URL = "http://localhost:5001/api/";

export const USERS_URL = BASE_URL + "users/";
export const ALL_MONTHLY_REPORT = BASE_URL + "all-monthly-report/";
export const DEVICES_URL = BASE_URL + "devices/";

export const LOCATIONS_URL = BASE_URL + "locations/";
export const MONTHLY_LOCATIONS_URL = LOCATIONS_URL + "monthly/";
export const DAILY_LOCATIONS_URL = LOCATIONS_URL + "daily-locations/";
export const DAILY_REPORT_URL = LOCATIONS_URL + "daily-report/";
export const MONTHLY_FILE_URL = MONTHLY_LOCATIONS_URL + "pdf/";

export const DAILY_SPEED_URL = LOCATIONS_URL + "speed-report/";