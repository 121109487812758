import { Card, Typography,Tooltip,IconButton,TextField,InputAdornment,MenuItem,Menu, Snackbar,Alert} from "@mui/material";
import {GetApp,Search,Clear,MoreVert,LocationOnOutlined} from '@mui/icons-material'
import {grey} from "@mui/material/colors"
import {useState,useContext,useEffect} from "react"
import {DeviceContext} from '../context/DeviceProvider'
import { Column, Table, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import {getVehicleType} from '../utils/types'
import {utils,writeFileXLSX} from "xlsx-js-style"
const IN_ACTIVE_DATE = 31


const headers=[
  "ID",
  "REG NO",
  "CHASIS NO",
  "MODEL",
  "CODE",
  "CUSTOMER NAME",
  "CUSTOMER CONTACT",
  "LAST UPDATES"
]


const rowStyle = (index) => {
    if (index % 2 === 0) {
      return { backgroundColor: grey[100] };
    } else {
      return { backgroundColor: grey[300] };
    }
  };

  const headerStyle = {
    color: grey[700],
  };

const Home = () => {
    const {devices,selectedDevice,
      setSelectedDevice,handleMapClick,
      handleDailyReportClick,
      handleMonthlyReportClick,
      handleDailyTravelReportClick,
      handleDailySpeedReportClick
    } = useContext(DeviceContext);
    const [state,setState] = useState({
        search:"",
        devices:[],
        open:false
    
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const handleSnackBarClose=()=>{
      setState(old=>({...old,open:false}))
    }


    // console.log("Seleted Device",selectedDevice)


    useEffect(()=>{
        setState(old=>({...old,devices:devices}))
    },[devices])



    const deviceFilter=(device)=>{

        return (
            (device.id && device.id.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.registration_number && device.registration_number.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.vehicle_model && device.vehicle_model.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.device_sim_number && device.device_sim_number.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.chasis_number && device.chasis_number.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.code && device.code.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.driver_name && device.driver_name.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.driver_phone && device.driver_phone.toLowerCase().includes(state.search.toLowerCase()))

        )

    }
    


    useEffect(()=>{
      if (state.search === "") {
        setState(old=>({...old,devices:devices}))
      } else {
      setState(old=>({...old,devices: devices.filter((device) => deviceFilter(device))}))
      }
    },[state.search,devices])





    const handleClose=()=>{

      setAnchorEl(null)
      setSelectedDevice(null)

    }

    const handleChange=e=>{
        setState(old=>({...old,search:e.target.value}))
    }

    const getColumnWidth=(data)=>{
      let arr=[
          {wch:data.reduce((w, r) => Math.max(w, r.id.length), headers[0].length)},
          {wch:data.reduce((w, r) => Math.max(w, r.registration_number.trim().length), headers[1].length)},
          {wch:data.reduce((w, r) => Math.max(w, r.chasis_number.trim().length), headers[2].length)},
          {wch:data.reduce((w, r) => Math.max(w, r.vehicle_model.trim().length), headers[3].length)},
          {wch:data.reduce((w, r) => Math.max(w, r.code.trim().length), headers[4].length)},
          {wch:data.reduce((w, r) => Math.max(w, r.driver_name.trim().length), headers[5].length)},
          {wch:data.reduce((w, r) => Math.max(w, r.driver_phone.trim().length), headers[6].length)},
          {wch:data.reduce((w, r) => Math.max(w, r.last_update.trim().length), headers[7].length)},
      ]
  
      return arr
    }

    const downloadFile=()=>{
    

      const dataMapper = x=>{
        let l={}
        l.id =x.id?x.id:"Undefined"
        l.registration_number =x.registration_number?x.registration_number:"Undefined"
        l.chasis_number =x.chasis_number?x.chasis_number:"Undefined"
        l.vehicle_model =x.vehicle_model?x.vehicle_model:"Undefined"
        l.code =x.code?x.code:"Undefined"
        l.driver_name =x.driver_name?x.driver_name:"Undefined"
        l.driver_phone =x.driver_phone?x.driver_phone:"Undefined"
        l.last_update =x.geo?x.geo.update_time:"Undefined"

        return l
      }

      let allData = devices.map(x=>dataMapper(x))
      let connectedData = getConnectedDevices().map(x=>dataMapper(x))
      let notConnectedData = getNotConnectedDevices().map(x=>dataMapper(x))
      let activeData = getActiveDevices().map(x=>dataMapper(x))
      let inActiveData = getInActiveDevices().map(x=>dataMapper(x))

      const allDevicesSheet = utils.json_to_sheet(allData);
      const connectedDevicesSheet = utils.json_to_sheet(connectedData);
      const notConnectedDevicesSheet = utils.json_to_sheet(notConnectedData);
      const activeDevicesSheet = utils.json_to_sheet(activeData);
      const inActiveDevicesSheet = utils.json_to_sheet(inActiveData);

      allDevicesSheet["!cols"] = getColumnWidth(allData);
      connectedDevicesSheet["!cols"] = getColumnWidth(connectedData);
      notConnectedDevicesSheet["!cols"] = getColumnWidth(notConnectedData);
      activeDevicesSheet["!cols"] = getColumnWidth(activeData);
      inActiveDevicesSheet["!cols"] = getColumnWidth(inActiveData);

      utils.sheet_add_aoa(allDevicesSheet, [headers], { origin: "A1" });
      utils.sheet_add_aoa(connectedDevicesSheet, [headers], { origin: "A1" });
      utils.sheet_add_aoa(notConnectedDevicesSheet, [headers], { origin: "A1" });
      utils.sheet_add_aoa(activeDevicesSheet, [headers], { origin: "A1" });
      utils.sheet_add_aoa(inActiveDevicesSheet, [headers], { origin: "A1" });

      const wb = utils.book_new();
      utils.book_append_sheet(wb, allDevicesSheet, "All Vehicles");
      utils.book_append_sheet(wb, connectedDevicesSheet, "Connected Vehicles");
      utils.book_append_sheet(wb, notConnectedDevicesSheet, "Not-Connected Vehicles");
      utils.book_append_sheet(wb, activeDevicesSheet, "Active Vehicles");
      utils.book_append_sheet(wb, inActiveDevicesSheet, "Inactive Vehicles");
      writeFileXLSX(wb, "vehicles.xlsx");




    }

    const setSearch =()=>{

    }

    const handleMoreClick=(event,device)=>{
      setAnchorEl(event.currentTarget);
      setSelectedDevice(device)
    }

    const handlePTIOReport=()=>{

    }

    const handleMonthlyTemperatureLogClick=()=>{

    }


    const onConnectedClick=()=>{
      setState(old=>({...old,devices:getConnectedDevices()}))
    }

    const onNotConnectedClick=()=>{
      setState(old=>({...old,devices:getNotConnectedDevices()}))
    }

    const onActiveClick=()=>{
      setState(old=>({...old,devices:getActiveDevices()}))
    }

    const onInactiveClick=()=>{
      setState(old=>({...old,devices:getInActiveDevices()}))
    }

    const onRunningClick=()=>{
      setState(old=>({...old,devices:getRunningDevices()}))
    }

    const onStoppedClick =()=>{
      setState(old=>({...old,devices:getStoppedDevices()}))
    }

    const getConnectedDevices=()=>{
      return devices.filter(x=>x.geo)
    }

    const getNotConnectedDevices=()=>{
      return devices.filter(x=>!x.geo)
    }

    const getActiveDevices=()=>{
      return devices.filter(x=>x.geo).filter(x=>x.geo.update_time && Math.floor((new Date()-new Date(x.geo.update_time))/(1000*60*60*24))<=IN_ACTIVE_DATE)
    }

    const getInActiveDevices=()=>{
      return devices.filter(x=>x.geo).filter(x=>!(x.geo.update_time && Math.floor((new Date()-new Date(x.geo.update_time))/(1000*60*60*24))<=IN_ACTIVE_DATE))
    }

    const getRunningDevices=()=>{
      return devices.filter(x=>x.geo && x.geo.acc==="ON")
    }

    const getStoppedDevices=()=>{
      return devices.filter(x=>x.geo && x.geo.acc==="OFF")
    }

  return (
    <div style={{ display: "flex", width: "100%", margin: 10 }}>
      <Card style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{ padding: 2 }}>
                Total Vehicles: ( {devices.length} )
              </Typography>
              <Typography style={{ padding: 2 }}>
                Searched Vehicles: ( {state.devices.length} )
              </Typography>
            </div>

            <div style={{display:"flex",minWidth:90,flexDirection:"column",alignItems:"center", cursor:"pointer",border:"2px solid black",borderRadius:4,padding:4}} onClick={onConnectedClick}>
              <Typography>Connected</Typography>
              <Typography>{getConnectedDevices().length}</Typography>
            </div>

            <div style={{display:"flex",minWidth:90,flexDirection:"column",alignItems:"center", cursor:"pointer",border:"2px solid black",borderRadius:4,padding:4}} onClick={onNotConnectedClick}>
              <Typography>Not-Connected</Typography>
              <Typography>{getNotConnectedDevices().length}</Typography>
            </div>

            <div style={{display:"flex",minWidth:90,flexDirection:"column",alignItems:"center", cursor:"pointer",border:"2px solid black",borderRadius:4,padding:4}} onClick={onActiveClick}>
              <Typography>Active</Typography>
              <Typography>{getActiveDevices().length}</Typography>
            </div>

            <div style={{display:"flex",minWidth:90,flexDirection:"column",alignItems:"center", cursor:"pointer",border:"2px solid black",borderRadius:4,padding:4}} onClick={onInactiveClick}>
              <Typography>Inactive</Typography>
              <Typography>{getInActiveDevices().length}</Typography>
            </div>

            <div style={{display:"flex",minWidth:90,flexDirection:"column",alignItems:"center", cursor:"pointer",border:"2px solid black",borderRadius:4,padding:4}} onClick={onRunningClick}>
              <Typography>Running</Typography>
              <Typography>{getRunningDevices().length}</Typography>
            </div>

            <div style={{display:"flex",minWidth:90,flexDirection:"column",alignItems:"center", cursor:"pointer",border:"2px solid black",borderRadius:4,padding:4}} onClick={onStoppedClick}>
              <Typography>Stopped</Typography>
              <Typography>{getStoppedDevices().length}</Typography>
            </div>

            <div style={{display:"flex",alignItems:"center"}}>

            <Tooltip title="Download">
              <IconButton style={{ marginRight: 10 }} onClick={downloadFile}>
                <GetApp />
              </IconButton>
            </Tooltip>

            <TextField
              placeholder="Search"
              onChange={handleChange}
              name="search"
              value={state.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")}>
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            </div>

          </div>

          <div style={{display:"flex",flex:1}}>
          <div style={{ width: "100%", height: "100%" }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={50}
                  rowCount={state.devices.length}
                  rowGetter={({ index }) => state.devices[index]}
                  rowStyle={({ index }) => rowStyle(index)}
                  headerStyle={headerStyle}
                >
                  <Column
                    width={60}
                    flexGrow={2}
                    label="ID"
                    dataKey="id"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={3}
                    label="Reg No"
                    dataKey="registration_number"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={3}
                    label="Chasis No"
                    dataKey="chasis_number"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={3}
                    label="Model"
                    dataKey="vehicle_model"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={3}
                    label="Code"
                    dataKey="code"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={2}
                    label="SIM"
                    dataKey="device_sim_number"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={2}
                    label="Driver/Cus"
                    dataKey="driver_name"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Type"
                    dataKey="vehicle_type"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? getVehicleType(cellData) : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Ignition"
                    dataKey="geo"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData.acc : "undefined"}
                      </Typography>
                    )}
                  />

                  <Column
                    headerStyle={{ textAlign: "right", marginRight: 20 }}
                    width={60}
                    flexGrow={1}
                    label="Action"
                    dataKey="action"
                    cellRenderer={({ rowData }) => (
                        <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
                              <Tooltip title="Map">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() =>rowData.geo? handleMapClick(rowData):setState(old=>({...old,open:true}))}
                          >
                            <LocationOnOutlined />
                          </IconButton>
                        </Tooltip>

                       

                        <Tooltip title="More..">
                          <IconButton
                            onClick={(e)=>handleMoreClick(e,rowData)}
                            style={{ marginLeft: 2, marginRight: 2 }}
                          >
                            <MoreVert />
                          </IconButton>
                        </Tooltip>

                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={handleDailyReportClick}>Daily Report</MenuItem>
                          <MenuItem onClick={handleMonthlyReportClick}>Monthly Report</MenuItem>
                          <MenuItem onClick={handleDailyTravelReportClick}>Daily Travel Report</MenuItem>
                          <MenuItem onClick={handleDailySpeedReportClick}>Speed Report</MenuItem>


                          {selectedDevice && selectedDevice.device_model==="S5E" && <MenuItem onClick={handleMonthlyTemperatureLogClick}>Monthly Temperature Log</MenuItem>}

                          { selectedDevice && selectedDevice.device_model==="S5E" && <MenuItem onClick={handlePTIOReport}>PTIO Report</MenuItem>}
                          
                         
                          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                        </Menu>

                        </div>
                   
                    )}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
          </div>
        </div>
      </Card>

      <Snackbar open={state.open} autoHideDuration={2000} onClose={handleSnackBarClose} anchorOrigin={{vertical: "top", horizontal:"right" }}>
          <Alert
            onClose={handleSnackBarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Device not yet Send Any Data to the Server
          </Alert>
        </Snackbar>
    </div>
  );
};

export default Home;




