import {useState,useEffect,useContext} from 'react'

import { GoogleMap,TrafficLayer } from "@react-google-maps/api";
import LocationMarker from './gmap/location_marker'
import {DeviceContext} from '../context/DeviceProvider'

const SingleMap=()=>{

    const {selectedDevice} = useContext(DeviceContext)

    const [center,setCenter] = useState({
        lat: selectedDevice.geo.lat,
        lng: selectedDevice.geo.lng,
      })
    
    
      const containerStyle = {
        height: "100%",
        width: "100%",
      };


    return(
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
          <LocationMarker device={selectedDevice} setCenter={setCenter} />
          <TrafficLayer />
        </GoogleMap>
    )
}

export default SingleMap