import { Card ,Typography,CircularProgress} from "@mui/material";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    Circle
  } from "@react-google-maps/api";
import { useState, useEffect, useContext } from "react";
import {DeviceContext} from '../context/DeviceProvider'
import BasicDatePicker from "./date_picker";
import UserDeviceAutoComplete from "./user_device_autocomplete";
import marker_blue from "../images/marker_blue.png";
import marker_red from "../images/marker_red.png";
import DailyPolyline from "./gmap/daily_polyline";
import TripPopup from "./gmap/trip-popup";
import axios from "axios";
import { DAILY_LOCATIONS_URL } from "../utils/urls";
import {getDistanceFromLocations} from '../utils/utils'

let defaultLatLng = {
  lat: 23.8103,
  lng: 90.4125,
};

const DailyTravelReport = () => {
    const {selectedDevice} = useContext(DeviceContext)
  const [state, setState] = useState({
    selectedDate: new Date(),
    distance: 0,
    center: { ...defaultLatLng },
    locations: [],
    location: null,
    progressVisible: false,
  });

  const setSelectedDate = (date) => {
    setState((old) => ({ ...old, selectedDate: new Date(date) }));
  };

  const setLocation = (location) => {
    setState((old) => ({ ...old, location: location }));
  };

  const getOtherLocations = () => {
    let ll = [...state.locations].slice(1, -1);
    return ll;
  };

  useEffect(() => {
    if (selectedDevice && state.selectedDate) {
      let postData = {
        device_id: selectedDevice.id,
        year: state.selectedDate.getFullYear(),
        month: state.selectedDate.getMonth(),
        day: state.selectedDate.getDate(),
        device_type: selectedDevice.vehicle_type,
      };
      setState(old=>({...old,progressVisible:true,locations:[]}))
      axios
        .post(DAILY_LOCATIONS_URL, postData)
        .then((response) => {
          setState(old=>({...old,progressVisible:false,locations:response.data}));
        })
        .catch((err) => console.log(err));
    }
  }, [selectedDevice, state.selectedDate]);

  useEffect(() => {
    

    let distance = getDistanceFromLocations(state.locations)
    let center = null
    if (state.locations.length > 0) {
        center ={lat:state.locations[0].geo.lat,lng:state.locations[0].geo.lng}
    } else {
        center ={...defaultLatLng}
    }

    setState(old=>({...old,distance:distance,center:center}))

  }, [state.locations]);

  return (
    <GoogleMap
          mapContainerStyle={{width:"100%",height:"100%"}}
          center={state.center}
          zoom={18}
        >
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",left: 0,top: 0,zIndex: 20000,padding: 10}}>

            <Card style={{ zIndex: 30000 ,padding:4}} >
                
            <BasicDatePicker label="Select Travel Date" date={state.selectedDate} dateChange={setSelectedDate}/>
            </Card>

            

            <Card style={{ zIndex: 30000 }}>
              <div
    
                style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                  width: 300,
                  height: 48,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {state.progressVisible?<CircularProgress color="inherit" />: <Typography variant="button">
                  Travel Distance {state.distance} KM
                </Typography>}
               
              </div>
            </Card>

            <UserDeviceAutoComplete/>

                

            </div>

            {state.locations.length > 0 && (
            <div>

            <DailyPolyline locations={state.locations} />
              
              <Marker
                onClick={() => setLocation(state.locations[0])}
                title="Start Position"
              
                icon={{
                  url: marker_red,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                position={{
                  lat: state.locations[0].geo.lat,
                  lng: state.locations[0].geo.lng,
                }}
              />
              {state.locations.length > 1 && (
                <Marker
                  title="End Position"
                  onClick={() =>
                    setLocation(state.locations[state.locations.length - 1])
                  }
                  icon={{
                    url: marker_blue,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  position={{
                    lat: state.locations[state.locations.length - 1].geo.lat,
                    lng: state.locations[state.locations.length - 1].geo.lng,
                  }}
                />
              )}

              {getOtherLocations().map((loc) => (

                  <Circle
                    onClick={() => setLocation(loc)}
                    key={loc._id}
                    center={{lat:loc.geo.lat,lng:loc.geo.lng}}
                    radius={10}
                    options={{strokeColor: "#000000",fillColor:"#ff0000",fillOpacity:1,strokeWeight:4}}
                    />
              ))}


            </div>
          )}

          {state.location && (
            <InfoWindow
              position={{
                lat: state.location.geo.lat,
                lng: state.location.geo.lng,
              }}
              onCloseClick={() => setLocation(null)}
            >
              <TripPopup location={state.location} />
            </InfoWindow>
          )}



        </GoogleMap>
  );
};

export default DailyTravelReport;
