
import React,{useContext} from "react";
import {DeviceContext} from '../context/DeviceProvider'
import MyAppBar from "./MyAppBar";
import MyDrawer from "./MyDrawer";

import Home from './Home'
import AllMap from "./AllMap";
import AllMonthlyReport from "./AllMonthlyReport";
import WorkshpoReport from "./WorkshpoReport";
import SingleMap from "./SingleMap";
import DailyReport from "./DailyReport";
import MonthlyReport from "./MonthlyReport";
import DailyTravelReport from "./DailyTravelReport";
import DailySpeedReport from "./DailySpeedReport";
import MobileMap from "./MobileMap";
import MobileDailyReport from "./MobileDailyReport";
import FirebaseProvider from "../context/FirebaseProvider";

import { MediaQueryContext } from "../context/MediaQueryProvider";

import HomeMobile from "./HomeMobile";

import {grey} from '@mui/material/colors'









const Main=()=>{

 const context = useContext(DeviceContext);

 const {isTabletOrMobile} = useContext(MediaQueryContext)

//  console.log(context)

 const getComponent=()=>{

    if(context.component_number===0){
        if(isTabletOrMobile){
            return <HomeMobile/>
        }else{
            return  <Home/>
        }
        
    }else if(context.component_number===1){
        
        return <AllMap/>
    }else if(context.component_number===2){
        return <AllMonthlyReport/>
    }else if(context.component_number===3){
        return <WorkshpoReport/>
    }else if(context.component_number===4){
        if(isTabletOrMobile){
            return <MobileMap/>
        }else{
            return <SingleMap/>
        }
        
    }else if(context.component_number===5){
        if(isTabletOrMobile){
            return <MobileDailyReport/>
        }else{
            return <DailyReport/>
        }
    }else if(context.component_number===6){
        return <MonthlyReport/>
    }else if(context.component_number===7){
        return <DailyTravelReport/>
    }else if(context.component_number===8){
        return <DailySpeedReport/>
    }
    else{
        return <Home/>
    }
 }


    return(
        <div style={{display:"flex",height:"100vh",overflow:"hidden",flexDirection:"column"}}>
            <MyAppBar/>
            <div style={{display:"flex",flex:1,backgroundColor:grey[300]}}>

                <FirebaseProvider>
                {getComponent()}
                </FirebaseProvider>

               
               
            </div>
            <MyDrawer/>
        </div>
    )
}

export default Main