import {Card, Typography} from '@mui/material'
import {useState,useContext,useEffect} from 'react'
import { CChart } from "@coreui/react-chartjs";

import { MyCard } from './component-utils'

import BasicDatePicker from './date_picker';
import UserDeviceAutoComplete from './user_device_autocomplete';
import {DeviceContext} from '../context/DeviceProvider'
import axios from 'axios';
import { DAILY_SPEED_URL } from "../utils/urls";

const DailySpeedReport=()=>{


    const {selectedDevice} = useContext(DeviceContext)

    const [state, setState] = useState({
        selectedDate: new Date(),
        progressVisible: false,
        info: { max: 0, min: 0, avg: 0 },
        data: [],
        frequency: [],
      });


      const minToStr = (timeInMin) => {
        let hour = Math.floor(timeInMin / 60);
        let min = timeInMin % 60;
    
        let suffix = "";
    
        let suff = Math.floor(hour / 12);
        hour = hour % 12;
    
        if (suff === 0) {
          suffix = " AM";
        } else {
          suffix = " PM";
        }
    
        if (hour === 0) {
          hour = 12;
        }
    
        return (
          String(hour).padStart(2, 0) + ":" + String(min).padStart(2, 0) + suffix
        );
      };
    
    
      const setSelectedDate=date=>{
        setState(oldState=>({...oldState,selectedDate:new Date(date)}));
      }

      useEffect(() => {
        if (selectedDevice && state.selectedDate) {
          let postData = {
            id: selectedDevice.id,
            year: state.selectedDate.getFullYear(),
            month: state.selectedDate.getMonth(),
            day: state.selectedDate.getDate(),
            device_type: selectedDevice.vehicle_type,
          };
          setState((old) => ({ ...old, progressVisible: true }));
          axios
            .post(DAILY_SPEED_URL, postData)
            .then((response) => {
              setState(old=>({...old,info:response.data.info,data:response.data.data,frequency:response.data.frequency,progressVisible:false}));
            })
            .catch((err) => console.log(err));
        }
      }, [selectedDevice, state.selectedDate]);

    return(
        <div
         style={{display:"flex",margin:10,width:"100%",flexDirection:"row"}}>

            <div style={{display:"flex",margin:10,flex:1,flexDirection:"column"}}>

                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",padding:10}}>
                <MyCard title="Max" value={state.info.max + " KMPH"} />
                <MyCard title="Avg" value={state.info.avg + " KMPH"} />
                <MyCard title="Min" value={state.info.min + " KMPH"} />
                   
                </div>

                <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                    <div style={{display:"flex",justifyContent:"center",padding:2}}>
                        <Typography variant="h6">Speed Timeline</Typography>
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <div style={{height:5,width:"40%",backgroundColor:"orange"}}></div>
                    </div>

                    <div style={{display:"flex",flex:1,justifyContent:"center"}}>
                    <CChart
                  style={{ width: "98%",marginTop:10 }}
                  type="line"
                  data={{
                    labels: state.data.map((x) => minToStr(x.time)),

                    datasets: [
                      {
                        label: "Average Speed Timeline",

                        backgroundColor: "rgba(151, 187, 205,1.0)",

                        borderColor: "rgba(151, 187, 205, 1)",

                        pointBackgroundColor: "rgba(151, 187, 205, 1)",

                        pointBorderColor: "#fff",

                        data: state.data.map((x) => x.avg),
                        spanGaps: true,
                      },
                    ],
                  }}
                />
                    </div>
                </div>

                


            </div>


            <div style={{display:"flex",margin:10,flex:1,flexDirection:"column"}}>

            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",padding:10}}>
               <Card style={{padding:6}}>

                <BasicDatePicker label="Select Report Date" date={state.selectedDate} dateChange={setSelectedDate}/>
                
               </Card>

               <Card style={{padding:6}}>
               <UserDeviceAutoComplete />

               </Card>

               
                   
                </div>

                <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                    <div style={{display:"flex",justifyContent:"center",padding:2}}>
                        <Typography variant="h6">Speed Frequencies Distribution</Typography>
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <div style={{height:5,width:"40%",backgroundColor:"orange"}}></div>
                    </div>

                    <div style={{display:"flex",flex:1,justifyContent:"center"}}>
                    <CChart
                style={{width:"98%" ,marginTop:10}}
                  type="bar"
                  data={{
                    labels: state.frequency.map(x=>x._id+" to "+(x._id+10)+" (K/M)"),

                    datasets: [
                      {
                        label: "Speed Frequencies",

                        backgroundColor: "#f87979",

                        data: state.frequency.map(x=>x.count),
                      },
                    ],
                  }}
                  labels="frequency"
                />
                    </div>
                </div>

            </div>


         </div>
    )
}

export default DailySpeedReport