import { useState, useContext, useEffect } from "react";
import { Card, Typography, IconButton, Tooltip } from "@mui/material";

import dateformat from "dateformat";
import axios from "axios";

import { ChevronLeft, ChevronRight, GetApp } from "@mui/icons-material";
import UserDeviceAutoComplete from "./user_device_autocomplete";
import { DeviceContext } from "../context/DeviceProvider";
import { MonthlyReportTable } from "./virtual_table";
import { MONTHLY_LOCATIONS_URL, MONTHLY_FILE_URL } from "../utils/urls";

const MonthlyReport = () => {
  const { selectedDevice } = useContext(DeviceContext);

  const [state, setState] = useState({
    date: new Date(),
    monthlyData: [],
  });

  const fetchMonthlyData = () => {
    let data = {
      device_id: selectedDevice.id,
      year: state.date.getFullYear(),
      month: state.date.getMonth(),
      device_type: selectedDevice.vehicle_type,
    };

    axios
      .post(MONTHLY_LOCATIONS_URL, data)
      .then((response) => {
        setState((old) => ({ ...old, monthlyData: response.data }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setState((old) => ({ ...old, monthlyData: [] }));
    fetchMonthlyData();
  }, [state.date, selectedDevice]);

  const prevClick = () => {
    let d = new Date(state.date);
    d.setMonth(d.getMonth() - 1);
    setState((old) => ({ ...old, date: d }));
  };

  const nextClick = () => {
    let d = new Date(state.date);
    d.setMonth(d.getMonth() + 1);
    setState((old) => ({ ...old, date: d }));
  };

  const getTotalDistance = () => {
    let d = state.monthlyData.reduce((acc, obj) => acc + obj.distance, 0);

    return (d / 1000).toFixed(2);
  };

  const fuelRequired = (distance, congestionTime) => {
    if (selectedDevice.mileage) {
      let disKm = distance / 1000;
      let runningConsumption = disKm / selectedDevice.mileage;

      if (selectedDevice.congestion_consumption) {
        let x = congestionTime / 3600;
        let congesCon = x * selectedDevice.congestion_consumption;
        runningConsumption = runningConsumption + congesCon;
      }

      return runningConsumption.toFixed(2);
    } else {
      return "undefined";
    }
  };

  const downloadFile = () => {
    let data = {
      device: selectedDevice,
      year: state.date.getFullYear(),
      month: state.date.getMonth(),
    };

    axios
      .post(MONTHLY_FILE_URL, data, { responseType: "blob" })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ display: "flex", width: "100%", margin: 10 }}>
      <Card style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 2,
                flexDirection: "column",
                padding: 10,
              }}
            >
              <Typography variant="button">Total Distance</Typography>
              <Typography variant="button">{getTotalDistance()} KM</Typography>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tooltip title="Previous">
                <IconButton onClick={prevClick}>
                  <ChevronLeft color="secondary" fontSize="large" />
                </IconButton>
              </Tooltip>

              <Typography style={{ marginLeft: 20, marginRight: 20 }}>
                Report on {dateformat(state.date, "mmmm-yyyy")}
              </Typography>
              <Tooltip title="Next">
                <IconButton onClick={nextClick}>
                  <ChevronRight color="secondary" fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                flex: 2,
                justifyContent: "flex-end",
                flexDirection: "row",
                alignItems: "center",
                padding: 10,
              }}
            >
              <UserDeviceAutoComplete />

              <Tooltip
                style={{ marginRight: 10, marginLeft: 10 }}
                title="Download"
              >
                <IconButton onClick={downloadFile}>
                  <GetApp color="secondary" fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
            <div style={{ width: "98%" }}>
              <MonthlyReportTable
                monthly_data={state.monthlyData}
                fuelRequired={fuelRequired}
                device={selectedDevice}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MonthlyReport;
