
import {useContext,useState,useEffect} from 'react'

import { GoogleMap, TrafficLayer } from "@react-google-maps/api";
import { TextField, InputAdornment, IconButton } from "@mui/material";

import { Search, Clear } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import ListMarker from './gmap/list_marker'
import ListInfoWindow from './gmap/list_info_window'
import {DeviceContext} from '../context/DeviceProvider'


// import ListMarker from './gmap/list_marker'
// import ListInfoWindow from './gmap/list_info_window'


let defaultLatLng = {
    lat: 23.8103,
    lng: 90.4125,
  };

const AllMap=()=>{
    const {devices}=useContext(DeviceContext)

    const containerStyle = {
        height: "100%",
        width: "100%",
      };
    
      const overlayStyle = {
        left: 0,
        top: 0,
        zIndex: 20000,
      };
  

    const [state,setState]=useState({
        search:"",
        center:{...defaultLatLng},
        searchDevices:[],
        infoData:null
    })


    const handleChange=e=>{
        setState(old=>({...old,search:e.target.value}))
    }

    const deviceFilter=(device)=>{

        return (
            (device.id && device.id.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.registration_number && device.registration_number.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.vehicle_model && device.vehicle_model.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.device_sim_number && device.device_sim_number.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.chasis_number && device.chasis_number.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.code && device.code.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.driver_name && device.driver_name.toLowerCase().includes(state.search.toLowerCase())) ||
            (device.driver_phone && device.driver_phone.toLowerCase().includes(state.search.toLowerCase()))

        )

    }

    const averageGeolocation = (devices) => {
        if (devices.length === 1) {
          return { lat: devices[0].geo.lat, lng: devices[0].geo.lng };
        }
    
        let x = 0.0;
        let y = 0.0;
        let z = 0.0;
    
        for (let dev of devices) {
          let latitude = (dev.geo.lat * Math.PI) / 180;
          let longitude = (dev.geo.lng * Math.PI) / 180;
    
          x += Math.cos(latitude) * Math.cos(longitude);
          y += Math.cos(latitude) * Math.sin(longitude);
          z += Math.sin(latitude);
        }
    
        let total = devices.length;
    
        x = x / total;
        y = y / total;
        z = z / total;
    
        let centralLongitude = Math.atan2(y, x);
        let centralSquareRoot = Math.sqrt(x * x + y * y);
        let centralLatitude = Math.atan2(z, centralSquareRoot);
    
        return {
          lat: (centralLatitude * 180) / Math.PI,
          lng: (centralLongitude * 180) / Math.PI,
        };
      };

    useEffect(()=>{
        if(state.search===""){
            let ss =devices.filter(dev=>dev.geo)
            if(ss.length>50){
              ss.sort(()=>Math.random()-0.5) // shuffle Arr
              ss = ss.slice(0,50)
              let center = averageGeolocation(ss)
                setState(old=>({...old,searchDevices:ss,center:center}))
            }else{
                let center = averageGeolocation(ss)
                setState(old=>({...old,searchDevices:ss,center:center}))
            }
            
        }else{
          let ss = devices.filter((device) =>device.geo && deviceFilter(device))
    
          if(ss.length>50){
            ss.sort(()=>Math.random()-0.5) // shuffle Arr
            ss = ss.slice(0,50)
            let center = averageGeolocation(ss)
            setState(old=>({...old,searchDevices:ss,center:center}))
          }else{
            let center = averageGeolocation(ss)
            setState(old=>({...old,searchDevices:ss,center:center}))
          }
              
        }
      },[state.search])

    const setSearch=val=>{
        setState(old=>({...state,search:val}))
    }


    const setInfoData=(infoData)=>{
        setState(old=>({...old,infoData:infoData}))
    }


    return (
       
        <GoogleMap  mapContainerStyle={containerStyle} center={state.center} zoom={state.searchDevices.length>1?12:15}>
        <TrafficLayer />

        {state.searchDevices.map((device)=>{
          return device && device.id && device.geo? <ListMarker key={device.id} device={device} setInfoData={setInfoData}/>:null
            })}

        <div
          style={{...overlayStyle,display:"flex",justifyContent:"center",alignItems:"center"}}
        >
          <div  style={{ paddingTop: 8 ,display:"flex"}}>
            <TextField
              placeholder="Search"
              onChange={handleChange}
              style={{ width: 600, backgroundColor: "white" }}
              variant="outlined"
              name="search"
              value={state.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")}>
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        {state.infoData && <ListInfoWindow infoData={state.infoData} setInfoData={setInfoData}/>}
        </GoogleMap>

    )
}

export default AllMap