import React,{useContext} from 'react'
import {TextField,Autocomplete} from '@mui/material'

import {DeviceContext} from '../context/DeviceProvider'


const UserDeviceAutoComplete = ()=>{

  const {devices,selectedDevice,setSelectedDevice} = useContext(DeviceContext)
    
    return(
        <Autocomplete
            id="combo-box-demo"
            options={[...devices].filter(dev=>dev.code && dev.code!=="")}
            getOptionLabel={(device) => device.code}
            style={{ width: 300 }}
            value={selectedDevice}
            onChange={(event,value,reason)=>value && setSelectedDevice(value)}
            renderInput={(params) => <TextField {...params} label="Select Code" variant="outlined" style={{backgroundColor:"#fff"}} />}
        />
    )


}

export default UserDeviceAutoComplete