import {useContext} from 'react'
import {AppBar,Toolbar,IconButton,Typography} from "@mui/material"
import {ArrowBack,Menu} from '@mui/icons-material'
import {DeviceContext} from '../context/DeviceProvider'

const MyAppBar=()=>{

    const {component_number,onBackClick,onMenuClick,selectedDevice} = useContext(DeviceContext);


    const getTitle =()=>{
        if(component_number===0){
            return "List of All Vehicles"
        }else if(component_number===1){
          return "All Vehicles in Map"
        }else if(component_number===2){
          return "All Vehicles Monthly Report"
        }else if(component_number===3){
          return "Workshop Report"
        }else if(component_number===4){
          return "Live Tracking of "+(selectedDevice && selectedDevice.code)
        }else if(component_number===5){
          return "Daily Report of "+(selectedDevice && selectedDevice.code)
        }else if(component_number===6){
          return "Monthly Report of "+(selectedDevice && selectedDevice.code)
        }else if(component_number===7){
          return "Daily Travel Report of "+(selectedDevice && selectedDevice.code)
        }else if(component_number===8){
          return "Daily Speed Report of "+(selectedDevice && selectedDevice.code)
        }
        else{
            return "Hello"
        }
    }

    

    return(
        <AppBar position='static'>
        <Toolbar>
            {component_number===0?( <IconButton onClick={onMenuClick}>
            <Menu style={{ color: "#fff" }} />
          </IconButton>):(<IconButton onClick={onBackClick}>
              <ArrowBack style={{ color: "#fff" }} />
            </IconButton>)}         
          <Typography style={{marginLeft:10}}>{getTitle()}</Typography>
        </Toolbar>
      </AppBar>
    )
}

export default MyAppBar