import Login from "./components/Login";
import Main from "./components/Main";
import DeviceProvider from "./context/DeviceProvider";
import MediaQueryProvider from "./context/MediaQueryProvider";
import {  LoadScript } from "@react-google-maps/api";
import { MAP_API_KEY } from "./utils/web_utils";


function App() {

  if(localStorage.getItem("user")){

    return(
      <LoadScript googleMapsApiKey={MAP_API_KEY}>

        <MediaQueryProvider>

        <DeviceProvider>
                <Main/>
            </DeviceProvider>

        </MediaQueryProvider>


      </LoadScript>
     
     
    )

  }else{
      return   (
        <MediaQueryProvider>
            <Login/>
      </MediaQueryProvider> 
      )
  }
}

export default App;
