import {
  Column,
  Table,
  AutoSizer,
  defaultTableRowRenderer
} from "react-virtualized";
import "react-virtualized/styles.css";
import {
  Typography,
  Card,
  IconButton,
  Menu,MenuItem
} from "@mui/material";
import { MoreVert,Add,Remove } from "@mui/icons-material";
import { useState, useRef,useEffect,useContext } from "react";
import {stop_duration,last_update} from '../utils/utils'
import { DeviceContext } from "../context/DeviceProvider";


export const DeviceList = ({ devices,openSnackbar }) => {
    const {handleMapClick,handleMobileDailyReportClick} = useContext(DeviceContext)
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const tableRef = useRef();


  useEffect(
    () => {
      tableRef.current.recomputeRowHeights();
    },
    [selectedIndex]
  );

  const _getRowHeight = ({ index }) => (index === selectedIndex ? 400 : 48);

  const _getDatum = index => devices[index % devices.length];

  const rowGetter = ({ index }) => _getDatum(index);

  const cellRenderer = ({ rowIndex,rowData }) => {
   
    const  BasicMenu=()=> {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {

          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };

        const onLiveTrackingClick=()=>{
            if(rowData.geo){
                handleMapClick(rowData)
            }else{
                openSnackbar()
            }
        }

        const onDailyReportClick=()=>{
            if(rowData.geo){
                handleMobileDailyReportClick(rowData)
            }else{
                openSnackbar()
            }
        }
      
        return (
          <div>
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVert fontSize="small" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={onLiveTrackingClick}>Live Tracking</MenuItem>
              <MenuItem onClick={onDailyReportClick}>Daily Report</MenuItem>
              <MenuItem onClick={handleClose}>Monthly Report</MenuItem>
              <MenuItem onClick={handleClose}>Travel Report</MenuItem>
              <MenuItem onClick={handleClose}>Speed Report</MenuItem>
            </Menu>
          </div>
        );
      }

    return(
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
            <IconButton onClick={()=>rowIndex!==selectedIndex?setSelectedIndex(rowIndex):setSelectedIndex(-1)}>
                {rowIndex!==selectedIndex?<Add fontSize="small"/>:<Remove fontSize="small"/>}
            </IconButton>

            {/* <IconButton>
            <MoreVert/>
            </IconButton> */}

            <BasicMenu/>


            
           
        </div>
    )
  };


  const MyRow=({title,value})=>{

    return(
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",padding:6}}>
        <Typography variant="button" fontSize={10}>{title}</Typography>
        <Typography variant="button" fontSize={10}>{value?value:"Undefined"}</Typography>
    </div>
    )

  }


  const rowRenderer = props => {
    const { index, style, className, key, rowData } = props;
    if (index === selectedIndex) {
      return (
        <Card style={{...style}} className={className} key={key}>
            <div
          style={{display: "flex", flexDirection: "column" }}
         
        >
          {defaultTableRowRenderer({
            ...props,
            style: { width: style.width, height: 48 }
          })}
          <div
            style={{
            width:"100%",
              display: "flex",
              flexDirection:"column"
            }}
          >
             <MyRow title="Engine No" value={rowData.registration_number}/>
             <MyRow title="Chasis No" value={rowData.chasis_number}/>
             <MyRow title="Customer Name" value={rowData.driver_name}/>
             <MyRow title="Customer Contact" value={rowData.driver_phone}/>
             <MyRow title="Speed" value={rowData.geo && rowData.geo.speed?rowData.geo.speed:"0"}/>
             <MyRow title="Ignition" value={rowData.geo && rowData.geo.acc}/>
             <MyRow title="Relay" value={rowData.geo && rowData.geo.fuel_line}/>
             <MyRow title="Charging" value={rowData.geo && rowData.geo.charging}/>
             <MyRow title="Voltage level" value={rowData.geo && rowData.geo.voltage_level}/>
             <MyRow title="Number of Satellite" value={rowData.geo && rowData.geo.number_of_satellite}/>
             <MyRow title="Stop Duration" value={rowData.geo?stop_duration(rowData.geo.active_time?rowData.geo.active_time:rowData.geo.update_time):"Undefined"}/>
             <MyRow title="Last Update" value={rowData.geo?last_update(rowData.geo.update_time):"Undefined"}/>

           
            
          </div>
        </div>

        </Card>
        
      );
    }
    return defaultTableRowRenderer(props);
  };


  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AutoSizer>
              {({ width, height }) => (
                <Table
                ref={tableRef}
                disableHeader={true}
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={_getRowHeight}
                  rowCount={devices.length}
                  rowGetter={rowGetter}
                  rowRenderer={rowRenderer}
                >

<Column
                    width={60}
                    flexGrow={6}
                    label="Reg No"
                    dataKey="code"
                    cellRenderer={({ cellData,rowData }) => (
                          <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                              <Typography variant="caption">
                        {cellData ? cellData : rowData.id}
                      </Typography>

                      <Typography variant="caption">
                        {rowData.driver_name ? rowData.driver_name : "Not Yet Sale"}
                      </Typography>

                        </div>
                    
                    )}
                  />
                  <Column
                    width={10}
                    flexGrow={1.8}
                    label="ID"
                    dataKey="id"
                    cellRenderer={cellRenderer}
                  />

                 

                

                </Table>
              )}
            </AutoSizer>
    </div>
  );
};
