import React, { useState,useContext } from "react";
import { Card, Typography, TextField, Button,Snackbar,Alert } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import bcImage from "../images/vts_ab.gif";
import { MediaQueryContext } from "../context/MediaQueryProvider";



const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#eeeeee",
  },
};

const LoginForm = () => {
  const {isTabletOrMobile} =useContext(MediaQueryContext)
  const [state, setState] = useState({
    email: "",
    password: "",
    error: null,
    open:false
  });

  const handleChange = (e) => {
    setState((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleClose=()=>{
    setState(old=>({...old,open:false}))
  }

  const handleLoginClick = () => {
    if (state.email === "rangs" && state.password === "rangs1234") {
      localStorage.setItem("user", JSON.stringify(state));
      window.location.reload();
    } else {
      setState(old=>({...old,open:true}))
    }
  };

  return (
    <div
      style={{
        width: isTabletOrMobile?"100%":"30%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          margin: 10,
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <TextField
          fullWidth
          required
          label="ID"
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="outlined"
          name="email"
          value={state.email || ""}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          required
          label="Password"
          name="password"
          value={state.password || ""}
          onChange={handleChange}
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="outlined"
          type="password"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Button variant="contained" onClick={handleLoginClick}>
          Login
        </Button>

        <Snackbar open={state.open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{vertical: "top", horizontal:"right" }}>
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Wrong Username Or Password
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

const Login = () => {

  const {isTabletOrMobile} =useContext(MediaQueryContext)
  

  if(isTabletOrMobile){
    return(
      <div style={{width:"100%", height:"100vh",overflow:"hidden",display:"flex", backgroundImage: "url(" + bcImage + ")",
      backgroundSize: "cover",justifyContent:"center",alignItems:"center"}}>
        <div style={{width:"80%", display:"flex",flexDirection:"column",border:"1px solid black",background:"#ffffff"}}>

          <div style={{display:"flex",justifyContent:"center",padding:10}}>
          <Typography variant="button">Welcome to Sultan Tracker</Typography>
          </div>

         
          <LoginForm />

       

         
         
        </div>
      </div>
    )
  }else{

    return (
  
      <div style={styles.container}>
        <Card square style={{ width: "60%", height: "60%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundImage: "url(" + bcImage + ")",
              backgroundSize: "cover",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffffff",
                  borderRadius: 10,
                  marginTop: 10,
                  padding: 10,
                }}
              >
                <AccountCircleIcon
                  style={{ fontSize: "2rem", color: "#0068bf" }}
                />
                <Typography
                  style={{ color: "#0068bf", marginLeft: 10 }}
                  variant="h6"
                >
                  WELCOME TO SULTAN TRACKER
                </Typography>
              </div>
            </div>
  
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                flex: 1,
              }}
            >
              <LoginForm />
            </div>
          </div>
        </Card>
      </div>
    );

  }
  
};

export default Login;
