import {useState,useContext,useEffect,useRef} from 'react'
import { DeviceContext } from '../context/DeviceProvider'

import { GoogleMap,TrafficLayer } from "@react-google-maps/api";
import MobileLocationMarker from './gmap/mobile_location_marker';

import anime from 'animejs/lib/anime.es.js';
import { IconButton, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import {stop_duration,last_update} from '../utils/utils'
import axios from 'axios';
import { grey } from '@mui/material/colors';

const plurl =
  "https://eu1.locationiq.com/v1/reverse.php?key=pk.5bb41df99bb77e1a059a77cfd4a61d15";


const MobileMap=()=>{

    const {selectedDevice} = useContext(DeviceContext)

    const [state,setState]=useState(
        {
            center:{
                lat: selectedDevice.geo.lat,
                lng: selectedDevice.geo.lng,
              },
              open:false,
              rotation:"180deg",
              currentGeo:{...selectedDevice.geo},
              address:""
        }
    )

    const refDiv = useRef()
    const animRef = useRef()


    const setCurrentGeo=(geo)=>{
      setState(old=>({...old,currentGeo:geo}))
    }

   


  useEffect(() => {

    if(state.open){
    let url = plurl + "&lat=" + state.currentGeo.lat + "&lon=" + state.currentGeo.lng + "&format=json"
    axios.get(url)
      .then(response => {
        if (response.data.display_name) {
         setState(old=>({...old,address:response.data.display_name}))
        }
      })
      .catch(err => console.log(err))
    }
   
  },[state.open])


   
    
    
      const containerStyle = {
        height: "100%",
        width: "100%",
      };


      useEffect(()=>{
       animRef.current= anime({
            targets: refDiv.current,
            translateY: state.open?0:-400,
            duration:1000,
            autoplay:false,
            
            begin:()=>{
              setState(old=>({...old,rotation:state.open?"180deg":"0deg"}))
          },
          complete:()=>{
              setState(old=>({...old,open:!old.open}))
          }
          });
      },[state.open])


      const onBoxClick=()=>{
        animRef.current.play()
      }


      const setCenter=(center)=>{
        setState(old=>({...old,center:center}))
      }


      const MyRow=({title,value})=>{

        return(
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",padding:6}}>
          <Typography fontSize={10} variant='button'>{title}</Typography>
          <Typography fontSize={10} textAlign="right" variant='button' style={{marginLeft:10}}>{value}</Typography>
          </div>
        )
      }
      


    return(
        <div  style={{
            position: "absolute",
            zIndex: 0,
            width: "100%", // or you can use width: '100vw'
            height: "100%" // or you can use height: '100vh'
            ,overflow:"hidden"
          }}>
   <GoogleMap options={{mapTypeControl:false,fullscreenControl:false,zoomControl:false,streetViewControl:false}}  mapContainerStyle={containerStyle} center={state.center} zoom={15}>
            
           
          
          <TrafficLayer />
          <MobileLocationMarker device={selectedDevice} setCenter={setCenter} onBoxClick={onBoxClick} setCurrentGeo={setCurrentGeo}/>
          
        </GoogleMap>

        <div
        ref={refDiv}
        
        style={{
            
        display:"flex",
        flexDirection:"column",
          zIndex: 1,
          position: "absolute",
          bottom: -350,
          left: 0,
          right:0,
          backgroundColor: "white", // you can use any color value
          width: "100%", // or you can use width: any_number
          height: 450 // or you can use height: any_number
        }}
      >
              <div onClick={onBoxClick} style={{display:"flex",backgroundColor:grey[500],height:50,width:"100%",justifyContent:"center",alignItems:"center"}}>
                <IconButton>
                    <ExpandMore fontSize='large' style={{rotate:state.rotation}}/>
                </IconButton>
              </div>
              <div style={{display:"flex",flex:1, flexDirection:"column"}}>

                <MyRow title="Customer Name" value={selectedDevice.driver_name}/>
                <MyRow title="Customer Contact" value={selectedDevice.driver_phone}/>
                <MyRow title="Engine No" value={selectedDevice.registration_number}/>
                <MyRow title="Chasis No" value={selectedDevice.chasis_number}/>
                <MyRow title="Speed" value={state.currentGeo.speed}/>
                <MyRow title="Ignition" value={state.currentGeo.acc}/>
                <MyRow title="Relay" value={state.currentGeo.fuel_line}/>
                <MyRow title="Charging" value={state.currentGeo.charging}/>
                <MyRow title="Voltage Level" value={state.currentGeo.voltage_level}/>
                <MyRow title="No of Satellite" value={state.currentGeo.number_of_satellite}/>
                <MyRow title="Stop Duration" value={state.currentGeo.active_time?stop_duration(state.currentGeo.active_time):stop_duration(state.currentGeo.update_time)}/>
                <MyRow title="Last Update" value={last_update(state.currentGeo.update_time)}/>
                <MyRow title="Address" value={state.address}/>


              </div>
            </div>
          </div>
      
    )
}

export default MobileMap