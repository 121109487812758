import React from "react";
import { Card, Typography } from "@mui/material";

export const MyCard = ({ title, value }) => {
  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography  variant="button">{title}</Typography>
        <div
          style={{ width: "100%", height: 2, backgroundColor: "#F18905" }}
        ></div>
        <Typography variant="button">{value}</Typography>
      </div>
    </Card>
  );
};

export const DashboardCard = ({ title, value }) => {

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
       
        padding: 4,
      }}
    >
      <Card style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography fontSize={10} variant="button">{title}</Typography>
          <div
            style={{ height: 2, backgroundColor: "orange", width: "90%" }}
          ></div>
          <Typography fontSize={10} variant="button">{value}</Typography>
        </div>
      </Card>
    </div>
  );
};





