import { useState, useEffect, useContext } from "react";
import { MyCard } from "./component-utils";
import { Card, Typography } from "@mui/material";
import { DeviceContext } from "../context/DeviceProvider";
import { formatDuration, HourlyReport, TripReport } from "./virtual_table";
import { DAILY_REPORT_URL } from "../utils/urls";
// import MyDatePicker from "./date_picker";
import BasicDatePicker from "./date_picker";
import UserDeviceAutoComplete from "./user_device_autocomplete";

// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";

const DailyReport = () => {
  const { selectedDevice, devices } = useContext(DeviceContext);

  const [state, setState] = useState({
    selectedDate: new Date(),
    progressVisible: false,
    trip_report: [],
    hourly_report: [],
    total_distance: 0,
    running_time: 0,
  });

  const setSelectedDate = (date) => {
    setState((old) => ({ ...old, selectedDate: new Date(date) }));
  };

  useEffect(() => {
    if (selectedDevice && state.selectedDate) {
      let postData = {
        device_id: selectedDevice.id,
        year: state.selectedDate.getFullYear(),
        month: state.selectedDate.getMonth(),
        day: state.selectedDate.getDate(),
        device_type: selectedDevice.vehicle_type,
      };
      axios
        .post(DAILY_REPORT_URL, postData)
        .then((response) => {
          setState((oldState) => ({
            ...oldState,
            trip_report: response.data.trip_report,
            hourly_report: response.data.hourly_report,
            total_distance: response.data.total_distance,
            running_time: response.data.running_time,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [selectedDevice, state.selectedDate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        margin: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          margin: 10,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <MyCard
            title="Distance"
            value={String(state.total_distance) + " KM"}
          />
          <MyCard title="Running" value={formatDuration(state.running_time)} />
          <MyCard
            title="Fuel"
            value={
              selectedDevice.milage
                ? (state.total_distance / selectedDevice.milage).toFixed(2) +
                  " Lit"
                : "Set Milage"
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: 10,
          }}
        >
          <Card style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h6">Trip Report</Typography>
              </div>
              <div
                style={{ width: "50%", height: 5, backgroundColor: "#F18905" }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  width: "98%",
                  marginTop: 10,
                }}
              >
                <TripReport trip_report={state.trip_report} />
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          margin: 10,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Card>
            <div style={{ padding: 10 }}>
              <BasicDatePicker
                label="Select Report Date"
                date={state.selectedDate}
                dateChange={setSelectedDate}
              />
            </div>
          </Card>

          <Card>
            <div style={{ padding: 10 }}>
              <UserDeviceAutoComplete />
            </div>
          </Card>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: 10,
          }}
        >
          <Card style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h6">Hourly Distance Report</Typography>
              </div>
              <div
                style={{ width: "50%", height: 5, backgroundColor: "#F18905" }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  width: "98%",
                  marginTop: 10,
                }}
              >
                <HourlyReport hourly_report={state.hourly_report} />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DailyReport;
