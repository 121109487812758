const vehicles = [
    "Car",
    "Bike",
    "Micro-Bus",
    "Bus",
    "Truck",
    "CNG",
    "Water-Vessel",
    "Tractor",
  ];
  
  const getVehicleType = (index) => {
    return vehicles[index - 1];
  };
  
  
  
  
  export { getVehicleType };