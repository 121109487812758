import React, { createContext } from "react";
import { initializeApp } from "firebase/app";
import {getDatabase,ref,child} from "firebase/database"
export const FirebaseContext = createContext();


const firebaseConfig = {
    apiKey: "AIzaSyAw4aWIgsCJvc9zoISFLiZDMokjJZ2HK_8",
    databaseURL: "https://sino-72f9d.firebaseio.com",
    projectId: "sino-72f9d",
    appId: "1:997737582322:web:e0988c01c9a4e3bc0c8a6b",
    measurementId: "G-TEDRPJLX4G",
  };


const FirebaseProvider =({children})=>{
    


    const app = initializeApp(firebaseConfig);

    const database = getDatabase(app);


    const getDeviceRef=(id)=>{
        return child(ref(database),"devices/"+id)
        
    }


    return(
        <FirebaseContext.Provider value={{
            getDeviceRef:getDeviceRef
            }}>
            {children}
        </FirebaseContext.Provider>
    )





}


export default FirebaseProvider