import {useState,useContext,useEffect} from 'react'


const MobileDailyReport=()=>{

    return <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column-reverse"}}>
        <div style={{display:"flex",height:100,backgroundColor:"green"}}></div>
        <div style={{display:"flex",flex:1,backgroundColor:"yellow"}}>fdsfds</div>
    </div>
}

export default MobileDailyReport