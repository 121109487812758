import { Column, Table, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import dateformat from "dateformat";
import {
  Typography,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { MoreVert } from "@mui/icons-material";

const headerStyle = {
  color: grey[700],
};


const rowStyle = (index) => {
    if (index % 2 === 0) {
      return { backgroundColor: grey[100] };
    } else {
      return { backgroundColor: grey[300] };
    }
  };
  
  const getSuffix = (n) => {
    if (n >= 11 && n <= 13) {
      return "th";
    }
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };


  const getDate = (cellData) => {
    return (
      String(cellData.day).padStart(2, 0) +
      "-" +
      String(cellData.month + 1).padStart(2, 0) +
      "-" +
      String(cellData.year)
    );
  };


  const formatTime = (timeStr) => {
    let arr = timeStr.split(":");
    let hr = Number(arr[0]);
    let min = Number(arr[1]);
    let suffix = "";
    if (Math.floor(hr / 12) === 0) {
      suffix = " AM";
    } else {
      hr = hr - 12;
      suffix = " PM";
    }
    if (hr === 0) {
      hr = 12;
    }
    return String(hr).padStart(2, 0) + ":" + String(min).padStart(2, 0) + suffix;
  };


  export const formatDuration = (time) => {
    let hr = Math.floor(time / 3600);
    let rem = time % 3600;
    let min = Math.floor(rem / 60);
  
    return (
      String(hr).padStart(2, 0) + " hrs " + String(min).padStart(2, 0) + " mins"
    );
  };


  export const HourlyReport = ({ hourly_report }) => {
    return (
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            rowCount={hourly_report.length}
            rowGetter={({ index }) => hourly_report[index]}
            rowStyle={({ index }) => rowStyle(index)}
            headerStyle={headerStyle}
          >
            <Column
              width={60}
              flexGrow={2}
              label="HOUR"
              dataKey="_id"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData + 1 + getSuffix(cellData + 1) + " Hr"}
                </Typography>
              )}
            />
  
            <Column
              width={60}
              flexGrow={3}
              label="START"
              dataKey="start"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData ? formatTime(cellData) : "undefined"}
                </Typography>
              )}
            />
  
            <Column
              width={60}
              flexGrow={3}
              label="STOP"
              dataKey="end"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData ? formatTime(cellData) : "undefined"}
                </Typography>
              )}
            />
  
            <Column
              width={60}
              flexGrow={3}
              label="DISTANCE"
              dataKey="distance"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData ? String(cellData).padEnd(2, 0) + " KM" : "0.00 KM"}
                </Typography>
              )}
            />
          </Table>
        )}
      </AutoSizer>
    );
  };

  export const TripReport = ({ trip_report }) => {
    return (
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            rowCount={trip_report.length}
            rowGetter={({ index }) => trip_report[index]}
            rowStyle={({ index }) => rowStyle(index)}
            headerStyle={headerStyle}
          >
            <Column
              width={60}
              flexGrow={2}
              label="STATUS"
              dataKey="status"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData === "OFF" ? "STOPPED" : "RUNNING"}
                </Typography>
              )}
            />
  
            <Column
              width={60}
              flexGrow={3}
              label="START"
              dataKey="start"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData ? formatTime(cellData) : "undefined"}
                </Typography>
              )}
            />
  
            <Column
              width={60}
              flexGrow={3}
              label="STOP"
              dataKey="end"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData ? formatTime(cellData) : "undefined"}
                </Typography>
              )}
            />
  
            <Column
              width={60}
              flexGrow={3}
              label="DURATION"
              dataKey="duration"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData ? formatDuration(cellData) : formatDuration(0)}
                </Typography>
              )}
            />
  
            <Column
              width={60}
              flexGrow={3}
              label="DISTANCE"
              dataKey="distance"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {cellData ? String(cellData).padEnd(2, 0) + " KM" : "0.00 KM"}
                </Typography>
              )}
            />
          </Table>
        )}
      </AutoSizer>
    );
  };


  export const MonthlyReportTable = ({ monthly_data, fuelRequired, device }) => {
    // console.log(monthly_data)
  
    return (
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            rowCount={monthly_data.length}
            rowGetter={({ index }) => monthly_data[index]}
            rowStyle={({ index }) => rowStyle(index)}
            headerStyle={headerStyle}
          >
            <Column
              width={80}
              flexGrow={1}
              label="Date"
              dataKey="_id"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{getDate(cellData)}</Typography>
              )}
            />
  
            <Column
              width={80}
              flexGrow={1}
              label="Start"
              dataKey="start_time"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {dateformat(cellData, "hh:MM TT")}
                </Typography>
              )}
            />
            <Column
              width={80}
              flexGrow={1}
              label="Stop"
              dataKey="end_time"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {dateformat(cellData, "hh:MM TT")}
                </Typography>
              )}
            />
  
            <Column
              width={80}
              flexGrow={1}
              label="Running"
              dataKey="running_time"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {formatDuration(cellData)}
                </Typography>
              )}
            />
  
            <Column
              width={80}
              flexGrow={1}
              label="Idle"
              dataKey="idle_time"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {formatDuration(cellData)}
                </Typography>
              )}
            />
  
            <Column
              width={80}
              flexGrow={1}
              label="Congestion"
              dataKey="congestion_time"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {formatDuration(cellData)}
                </Typography>
              )}
            />
  
            <Column
              width={80}
              flexGrow={1}
              label="Distance(km)"
              dataKey="distance"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">
                  {(cellData / 1000).toFixed(2)}
                </Typography>
              )}
            />
  
            {device.mileage && (
              <Column
                width={80}
                flexGrow={1}
                label="Fuel(km)"
                dataKey="distance"
                cellRenderer={({ rowData }) => (
                  <Typography variant="caption">
                    {fuelRequired(rowData.distance, rowData.congestion_time)}
                  </Typography>
                )}
              />
            )}
          
          </Table>
        )}
      </AutoSizer>
    );
  };


export const AllMonthlyReportTable = ({ data }) => {
  
    const formatTimeString=str=>{
      return str.slice(0,-11)
    }
  
    return (
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
            rowStyle={({ index }) => rowStyle(index)}
            headerStyle={headerStyle}
          >
            <Column
              width={80}
              flexGrow={1}
              label="ID"
              dataKey="id"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{cellData}</Typography>
              )}
            />
  
            <Column
              width={80}
              flexGrow={1}
              label="CODE"
              dataKey="code"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{cellData}</Typography>
              )}
            />
            <Column
              width={80}
              flexGrow={1}
              label="REG NO"
              dataKey="registration_number"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{cellData}</Typography>
              )}
            />
  
  <Column
              width={80}
              flexGrow={1}
              label="Model"
              dataKey="model"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{cellData}</Typography>
              )}
            />
  
  <Column
              width={80}
              flexGrow={1}
              label="Day Count"
              dataKey="day_count"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{cellData}</Typography>
              )}
            />
  
  <Column
              width={80}
              flexGrow={1}
              label="Locations"
              dataKey="location_count"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{cellData}</Typography>
              )}
            />
  
  <Column
              width={80}
              flexGrow={1}
              label="Running Time"
              dataKey="running_time"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{formatTimeString(cellData)}</Typography>
              )}
            />
  
  <Column
              width={80}
              flexGrow={1}
              label="Jam Time"
              dataKey="congestion_time"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{formatTimeString(cellData)}</Typography>
              )}
            />
  
  <Column
              width={80}
              flexGrow={1}
              label="Idle Time"
              dataKey="idle_time"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{formatTimeString(cellData)}</Typography>
              )}
            />
  
  <Column
              width={80}
              flexGrow={1}
              label="Distance(KM)"
              dataKey="distance"
              cellRenderer={({ cellData }) => (
                <Typography variant="caption">{cellData}</Typography>
              )}
            />
  
  
          </Table>
        )}
      </AutoSizer>
    );
  };



export const WorkshopReport=({data})=>{

    return (
        <AutoSizer>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              headerHeight={50}
              rowHeight={50}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              rowStyle={({ index }) => rowStyle(index)}
              headerStyle={headerStyle}
            >
              <Column
                width={80}
                flexGrow={1}
                label="ID"
                dataKey="id"
                cellRenderer={({ cellData }) => (
                  <Typography variant="caption">{cellData}</Typography>
                )}
              />
    
              <Column
                width={80}
                flexGrow={1}
                label="CODE"
                dataKey="code"
                cellRenderer={({ cellData }) => (
                  <Typography variant="caption">{cellData}</Typography>
                )}
              />
              <Column
                width={80}
                flexGrow={1}
                label="Engine No"
                dataKey="registration_number"
                cellRenderer={({ cellData }) => (
                  <Typography variant="caption">{cellData}</Typography>
                )}
              />
    
            <Column
                width={80}
                flexGrow={1}
                label="Model"
                dataKey="vehicle_model"
                cellRenderer={({ cellData }) => (
                  <Typography variant="caption">{cellData}</Typography>
                )}
              />

<Column
                width={80}
                flexGrow={1}
                label="SIM NO"
                dataKey="device_sim_number"
                cellRenderer={({ cellData }) => (
                  <Typography variant="caption">{cellData}</Typography>
                )}
              />

<Column
                width={80}
                flexGrow={1}
                label="CUSTOMER"
                dataKey="driver_name"
                cellRenderer={({ cellData }) => (
                  <Typography variant="caption">{cellData}</Typography>
                )}
              />

<Column
                width={80}
                flexGrow={1}
                label="CUSTOMER CONTACT"
                dataKey="driver_phone"
                cellRenderer={({ cellData }) => (
                  <Typography variant="caption">{cellData}</Typography>
                )}
              />

<Column
                width={80}
                flexGrow={1}
                label="WORKSHOP"
                dataKey="work_shop"
                cellRenderer={({ cellData }) => (
                  <Typography variant="caption">{cellData}</Typography>
                )}
              />
    
    
            </Table>
          )}
        </AutoSizer>
      );

}
  