// WorkshpoReport

import {utils,writeFileXLSX} from "xlsx-js-style"

import axios from 'axios'
import {useState,useEffect} from 'react'

import {Card,Typography,TextField,InputAdornment,IconButton,Tooltip} from '@mui/material'
import {Search,Clear,GetApp} from "@mui/icons-material"
import {grey} from '@mui/material/colors'
import {WorkshopReport} from './virtual_table'
import {DEVICES_URL} from "../utils/urls"



const headers=[
    "ID",
    "CODE",
    "ENGINE NO",
    "Model",
    "CUSTOMER",
    "CUSTOMER CONTACT",
    "WORKSHOP",
]

const WorkshpoReport=()=>{

    const [state,setState] = useState({
        data:[],
        original:[],
        search:""
    })


    const handleChange=(e)=>{
        setState(old=>({...old,search:e.target.value}))
    }


    useEffect(()=>{

        setState(old=>({...old,data:[...old.original].filter(x=>{
            return(x.id.toLowerCase().includes(state.search.toLowerCase()) || 
                x.code.toLowerCase().includes(state.search.toLowerCase()) ||
                x.device_sim_number.toLowerCase().includes(state.search.toLowerCase()) ||
                x.work_shop.toLowerCase().includes(state.search.toLowerCase()) ||
                x.driver_name.toLowerCase().includes(state.search.toLowerCase()) ||
                x.driver_phone.toLowerCase().includes(state.search.toLowerCase()) 
                )
        })}))

    },[state.search])


    useEffect(()=>{
        axios
        .get(DEVICES_URL, { headers: { Authorization: "Bearer " + "!!!rangsAnupRang###" } })
        .then((response) => {
          setState(old=>({...old,data:response.data,original:response.data}))
        })
        .catch((err) => {
          throw err;
        });

    },[])


    const getColumnWidth=()=>{
    
        let data = [...state.data]
        let arr=[
            {wch:data.reduce((w, r) => Math.max(w, r.id.length), headers[0].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.code.trim().length), headers[1].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.registration_number.trim().length), headers[2].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.vehicle_model.trim().length), headers[3].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.driver_name.trim().length), headers[4].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.driver_phone.trim().length), headers[5].length)},
            {wch:data.reduce((w, r) => Math.max(w, r.work_shop.trim().length), headers[6].length)},
        ]
    
        return arr
      }


    const downloadFile=()=>{

        const ws = utils.json_to_sheet([...state.data].map(x=>{
            let l ={}
            l.id=x.id
            l.code=x.code
            l.registration_number=x.registration_number
            l.vehicle_model=x.vehicle_model
            l.driver_name=x.driver_name
            l.driver_phone=x.driver_phone
            l.work_shop=x.work_shop

            return l
        }));
        ws["!cols"] = getColumnWidth();
        utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Workshop");
        writeFileXLSX(wb, "Workshop.xlsx");

    }


    return (
        <div style={{ display: "flex", width: "100%", margin: 10 }}>
      <Card style={{ width: "100%", height: "100%" }}>
      <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                <div style={{display:"flex",flexDirection:"column",padding:10}}>
                <Typography variant="button">No of Vehicles</Typography>
                {state.data &&  <Typography variant="body1">{state.data.length} Nos</Typography>}

                </div>
                <div style={{display:"flex",flexDirection:"row",padding:10,alignItems:"center"}}>

                <Tooltip title="Download" style={{marginRight:20}}>
                  <IconButton onClick={downloadFile}>
                    <GetApp fontSize="medium" />
                  </IconButton>
                </Tooltip>


                <TextField
                            placeholder="Search ......."
                            onChange={handleChange}
                            name="search"
                            value={state.search}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search
                                            style={{
                                                color: grey[700],
                                                padding: 4,
                                                cursor: "pointer",
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setState(old=>({...old,search:""}))}>
                                            <Clear
                                                style={{
                                                    color: grey[700],
                                                    padding: 4,
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                </div>
            </div>

            <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
              <div style={{ width: "98%" }}>
              <WorkshopReport data={state.data} />
              </div>
            </div>
        </div>
      </Card>
      </div>
    )
}

export default WorkshpoReport