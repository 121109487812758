import {
  Card,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
  Menu,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GetApp,
  Search,
  Clear,
  ExpandMore,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { useState, useContext, useEffect } from "react";
import { DeviceContext } from "../context/DeviceProvider";
import { getVehicleType } from "../utils/types";
import { utils, writeFileXLSX } from "xlsx-js-style";
import { DashboardCard } from "./component-utils";
import { DeviceList } from "./virtual_list";
const IN_ACTIVE_DATE = 31;

const headers = [
  "ID",
  "REG NO",
  "CHASIS NO",
  "MODEL",
  "CODE",
  "CUSTOMER NAME",
  "CUSTOMER CONTACT",
  "LAST UPDATES",
];

const useStyles = makeStyles({
  test: {
    "&:before": {
      display: "none",
    },
  },
});

const HomeMobile = () => {
  const classes = useStyles();

  const {
    devices,
    selectedDevice,
    setSelectedDevice,
    handleMapClick,
    handleDailyReportClick,
    handleMonthlyReportClick,
    handleDailyTravelReportClick,
    handleDailySpeedReportClick,
  } = useContext(DeviceContext);
  const [state, setState] = useState({
    search: "",
    devices: [],
    open: false,
  });

  const handleSnackBarClose = () => {
    setState((old) => ({ ...old, open: false }));
  };

  // console.log("Seleted Device",selectedDevice)

  useEffect(() => {
    setState((old) => ({ ...old, devices: devices }));
  }, [devices]);

  useEffect(() => {
    if (state.search === "") {
      setState((old) => ({ ...old, devices: devices }));
    } else {
      setState((old) => ({
        ...old,
        devices: devices.filter((device) => deviceFilter(device)),
      }));
    }
  }, [state.search, devices]);

  const deviceFilter = (device) => {
    return (
      (device.id &&
        device.id.toLowerCase().includes(state.search.toLowerCase())) ||
      (device.registration_number &&
        device.registration_number
          .toLowerCase()
          .includes(state.search.toLowerCase())) ||
      (device.vehicle_model &&
        device.vehicle_model
          .toLowerCase()
          .includes(state.search.toLowerCase())) ||
      (device.device_sim_number &&
        device.device_sim_number
          .toLowerCase()
          .includes(state.search.toLowerCase())) ||
      (device.chasis_number &&
        device.chasis_number
          .toLowerCase()
          .includes(state.search.toLowerCase())) ||
      (device.code &&
        device.code.toLowerCase().includes(state.search.toLowerCase())) ||
      (device.driver_name &&
        device.driver_name
          .toLowerCase()
          .includes(state.search.toLowerCase())) ||
      (device.driver_phone &&
        device.driver_phone.toLowerCase().includes(state.search.toLowerCase()))
    );
  };

  const getColumnWidth = (data) => {
    let arr = [
      {
        wch: data.reduce((w, r) => Math.max(w, r.id.length), headers[0].length),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.registration_number.trim().length),
          headers[1].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.chasis_number.trim().length),
          headers[2].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.vehicle_model.trim().length),
          headers[3].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.code.trim().length),
          headers[4].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.driver_name.trim().length),
          headers[5].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.driver_phone.trim().length),
          headers[6].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.last_update.trim().length),
          headers[7].length
        ),
      },
    ];

    return arr;
  };

  const handleChange = (e) => {
    setState((old) => ({ ...old, search: e.target.value }));
  };

  const onConnectedClick = () => {
    setState((old) => ({ ...old, devices: getConnectedDevices() }));
  };

  const onNotConnectedClick = () => {
    setState((old) => ({ ...old, devices: getNotConnectedDevices() }));
  };

  const onActiveClick = () => {
    setState((old) => ({ ...old, devices: getActiveDevices() }));
  };

  const onInactiveClick = () => {
    setState((old) => ({ ...old, devices: getInActiveDevices() }));
  };

  const onRunningClick = () => {
    setState((old) => ({ ...old, devices: getRunningDevices() }));
  };

  const onStoppedClick = () => {
    setState((old) => ({ ...old, devices: getStoppedDevices() }));
  };

  const getConnectedDevices = () => {
    return devices.filter((x) => x.geo);
  };

  const getNotConnectedDevices = () => {
    return devices.filter((x) => !x.geo);
  };

  const getActiveDevices = () => {
    return devices
      .filter((x) => x.geo)
      .filter(
        (x) =>
          x.geo.update_time &&
          Math.floor(
            (new Date() - new Date(x.geo.update_time)) / (1000 * 60 * 60 * 24)
          ) <= IN_ACTIVE_DATE
      );
  };

  const getInActiveDevices = () => {
    return devices
      .filter((x) => x.geo)
      .filter(
        (x) =>
          !(
            x.geo.update_time &&
            Math.floor(
              (new Date() - new Date(x.geo.update_time)) / (1000 * 60 * 60 * 24)
            ) <= IN_ACTIVE_DATE
          )
      );
  };

  const getRunningDevices = () => {
    return devices.filter((x) => x.geo && x.geo.acc === "ON");
  };

  const getStoppedDevices = () => {
    return devices.filter((x) => x.geo && x.geo.acc === "OFF");
  };

  const downloadFile = () => {
    const dataMapper = (x) => {
      let l = {};
      l.id = x.id ? x.id : "Undefined";
      l.registration_number = x.registration_number
        ? x.registration_number
        : "Undefined";
      l.chasis_number = x.chasis_number ? x.chasis_number : "Undefined";
      l.vehicle_model = x.vehicle_model ? x.vehicle_model : "Undefined";
      l.code = x.code ? x.code : "Undefined";
      l.driver_name = x.driver_name ? x.driver_name : "Undefined";
      l.driver_phone = x.driver_phone ? x.driver_phone : "Undefined";
      l.last_update = x.geo ? x.geo.update_time : "Undefined";

      return l;
    };

    let allData = devices.map((x) => dataMapper(x));
    let connectedData = getConnectedDevices().map((x) => dataMapper(x));
    let notConnectedData = getNotConnectedDevices().map((x) => dataMapper(x));
    let activeData = getActiveDevices().map((x) => dataMapper(x));
    let inActiveData = getInActiveDevices().map((x) => dataMapper(x));

    const allDevicesSheet = utils.json_to_sheet(allData);
    const connectedDevicesSheet = utils.json_to_sheet(connectedData);
    const notConnectedDevicesSheet = utils.json_to_sheet(notConnectedData);
    const activeDevicesSheet = utils.json_to_sheet(activeData);
    const inActiveDevicesSheet = utils.json_to_sheet(inActiveData);

    allDevicesSheet["!cols"] = getColumnWidth(allData);
    connectedDevicesSheet["!cols"] = getColumnWidth(connectedData);
    notConnectedDevicesSheet["!cols"] = getColumnWidth(notConnectedData);
    activeDevicesSheet["!cols"] = getColumnWidth(activeData);
    inActiveDevicesSheet["!cols"] = getColumnWidth(inActiveData);

    utils.sheet_add_aoa(allDevicesSheet, [headers], { origin: "A1" });
    utils.sheet_add_aoa(connectedDevicesSheet, [headers], { origin: "A1" });
    utils.sheet_add_aoa(notConnectedDevicesSheet, [headers], { origin: "A1" });
    utils.sheet_add_aoa(activeDevicesSheet, [headers], { origin: "A1" });
    utils.sheet_add_aoa(inActiveDevicesSheet, [headers], { origin: "A1" });

    const wb = utils.book_new();
    utils.book_append_sheet(wb, allDevicesSheet, "All Vehicles");
    utils.book_append_sheet(wb, connectedDevicesSheet, "Connected Vehicles");
    utils.book_append_sheet(
      wb,
      notConnectedDevicesSheet,
      "Not-Connected Vehicles"
    );
    utils.book_append_sheet(wb, activeDevicesSheet, "Active Vehicles");
    utils.book_append_sheet(wb, inActiveDevicesSheet, "Inactive Vehicles");
    writeFileXLSX(wb, "vehicles.xlsx");
  };

  const openSnackbar = () => {
    setState((old) => ({ ...old, open: true }));
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        margin: 10,
      }}
    >
      <div style={{ width: "100%",marginBottom:4 }}>
        <Card>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: 4,
              flexDirection: "row",
            }}
          >
            <TextField
              placeholder="Search"
              onChange={handleChange}
              name="search"
              size="small"
              value={state.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setState((old) => ({ ...old, search: "" }))
                      }
                    >
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Tooltip title="Download">
              <IconButton style={{ marginRight: 10 }} onClick={downloadFile}>
                <GetApp />
              </IconButton>
            </Tooltip>
          </div>
        </Card>
      </div>

      <Accordion style={{ margin: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>General Info of {devices.length} Vehicles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "orange",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <DashboardCard
                title="Connected"
                value={getConnectedDevices().length + " Nos"}
              />
              <DashboardCard
                title="Not-Connect"
                value={getNotConnectedDevices().length + " Nos"}
              />
              <DashboardCard
                title="Inactive"
                value={getInActiveDevices().length + " Nos"}
              />
            </div>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <DashboardCard
                title="Active"
                value={getActiveDevices().length + " Nos"}
              />
              <DashboardCard
                title="Running"
                value={getRunningDevices().length + " Nos"}
              />
              <DashboardCard
                title="Stopped"
                value={getStoppedDevices().length + " Nos"}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <DeviceList devices={state.devices} openSnackbar={openSnackbar} />
      </div>

      <Snackbar
        open={state.open}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Device not yet Send Any Data to the Server
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HomeMobile;
