import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

import {USERS_URL} from '../utils/urls'

export const DeviceContext = createContext();


const idToken = "!!!rangsAnupRang###"
const uid = "5f804b11bbd58b4782eacbb5"


const DeviceProvider=({children})=>{

    const [state,setState] = useState({
        devices:[],
        selectedDevice:null,
        component_number:0,
        component_stack:[],
        drawer_open:false
    })


    const handleMapClick=(device)=>{

      let old ={...state}
      old.component_stack = [...old.component_stack,old.component_number]
      old.component_number=4
      old.selectedDevice=device
      setState(old)

    }

    const handleMobileDailyReportClick=(device)=>{
      let old ={...state}
      old.component_stack = [...old.component_stack,old.component_number]
      old.component_number=5
      old.selectedDevice=device
      setState(old)

    }

    const handleDailyReportClick=()=>{
      let old ={...state}
      old.component_stack = [...old.component_stack,old.component_number]
      old.component_number=5
      setState(old)

    }

    const handleMonthlyReportClick=()=>{
      let old ={...state}
      old.component_stack = [...old.component_stack,old.component_number]
      old.component_number=6
      setState(old)
    }

    const handleDailyTravelReportClick=()=>{
      let old ={...state}
      old.component_stack = [...old.component_stack,old.component_number]
      old.component_number=7
      setState(old)
    }

    const handleDailySpeedReportClick=()=>{
      let old ={...state}
      old.component_stack = [...old.component_stack,old.component_number]
      old.component_number=8
      setState(old)
    }


    const handleDrawerClose=()=>{
      setState(old=>({...old,drawer_open:false}))
    }


    const loadComponent=index=>{
      let old ={...state}
      old.component_stack = [...old.component_stack,old.component_number]
      old.component_number=index
      setState(old)
    }

    const handleDrawerItemClick=(index)=>{
      if(index!==state.component_number){
        if(index===4){
          //logout
          localStorage.removeItem("user")
          window.location.reload();
        }else{
          loadComponent(index)
        }
      }
    }

    const onMenuClick=()=>{
      setState(old=>({...old,drawer_open:true}))
    }


    const onBackClick=()=>{
      let old = {...state}
      old.component_number = old.component_stack.pop()

      console.log(old)
      setState(old)
    }


    const setSelectedDevice=device=>{
      setState(old=>({...old,selectedDevice:device}))
    }


    useEffect(()=>{

        if(state.devices.length===0){
            axios
            .get(USERS_URL + uid+"/devices", {
              headers: { Authorization: "Bearer " + idToken },
            })
            .then((response) => {
              setState(old=>({...old,devices:response.data}))
            })
            .catch((err) => {
              throw err;
            });
        }

    },[])


    return(
        <DeviceContext.Provider value={{...state,
        onBackClick:onBackClick,
        handleDrawerClose:handleDrawerClose,
        handleDrawerItemClick:handleDrawerItemClick,
        onMenuClick:onMenuClick,
        setSelectedDevice:setSelectedDevice,
        handleMapClick:handleMapClick,
        handleDailyReportClick:handleDailyReportClick,
        handleMonthlyReportClick:handleMonthlyReportClick,
        handleDailyTravelReportClick:handleDailyTravelReportClick,
        handleDailySpeedReportClick:handleDailySpeedReportClick,
        handleMobileDailyReportClick:handleMobileDailyReportClick,
        }}>
            {children}
        </DeviceContext.Provider>
    )
}

export default DeviceProvider


