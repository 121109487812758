import {useContext} from 'react'
import {Drawer,Box,List,ListItem,ListItemIcon,ListItemText} from '@mui/material'
import {DeviceContext} from '../context/DeviceProvider'
import logo from "../images/rangs_logo.jpg"


import {
    Home,
    LocationOn,
    ExitToApp,
    Assessment
  } from "@mui/icons-material";


const drawerList = [
    { label: "Home", icon: Home },
    { label: "All Vehicles", icon: LocationOn },
    { label: "Monthly Report", icon: Assessment },
    { label: "Workshop Report", icon: Assessment },
    { label: "Logout", icon: ExitToApp },
  ];


const MyDrawer =()=>{

    const {component_number,drawer_open,handleDrawerClose,handleDrawerItemClick} = useContext(DeviceContext);


    return (
        <Drawer
          anchor="left"
          open={drawer_open}
          onClose={handleDrawerClose}
          style={{ maxHeight: "100vh" }}
        >
          <Box display="flex" flexDirection="column" style={{width: 300,height: "100vh"}}>
            <Box display="flex" justifyContent="center">
                <img src={logo} alt="Rangs Logo"/>
                
            </Box>
            <Box display="flex" flexGrow="1" alignItems="stretch">
            <List style={{width:"100%"}}>
              {drawerList.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  divider
                  onClick={() => handleDrawerItemClick(index)}
                    selected={index===component_number}
                >
                  <ListItemIcon>{<item.icon color="primary" />}</ListItemIcon>
                  <ListItemText style={{ textTransform: "uppercase" }}>
                    {item.label}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            </Box>
          </Box>
          
        </Drawer>
      );
}

export default MyDrawer